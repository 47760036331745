<template>
  <div class="suspicious-group-join-investigator">
    <div class="title">היי {{ this.householdName }},</div>
    <suspicious-group-join-reported v-if="reportApproved"/>
    <suspicious-group-join-question v-else/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SuspiciousGroupJoinReported from './SuspiciousGroupJoinReported.vue';
import SuspiciousGroupJoinQuestion from './SuspiciousGroupJoinQuestion.vue';
import Segment from '../../../../Segment';

export default {
  name: 'SuspiciousGroupJoinInvestigator',
  components: {
    SuspiciousGroupJoinReported,
    SuspiciousGroupJoinQuestion,
  },
  async created() {
    Segment.trackUserGot('SuspiciousGroupJoinInvestigatorEntered');
  },
  computed: {
    ...mapGetters('session', ['householdName']),
    ...mapState('suspiciousGroupJoin', ['reportApproved']),
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../scss/mixins';

  .suspicious-group-join-investigator {
    margin: 30px;

    .title {
      @include title;
    }
  }
</style>
