<template>
  <div class="suspicious-group-join-reported">
    <div class="text">קיבלתי את הדיווח על מס׳ לא מוכר שהצטרף לקבוצה.</div>
    <div>אני אעצור את הודעות התזרים עד שהצוות שלנו יחקור את העניין.</div>
    <div class="text-bold">נהיה בקשר בהקדם. תודה!</div>
    <div class="text">בינתיים, לכל שאלה -</div>
    <div class="text-sub">אנחנו זמינים כאן:</div>
    <div class="link">support@riseup.co.il</div>
  </div>
</template>

<script>
export default {
  name: 'SuspiciousGroupJoinReported',
};
</script>

<style lang="scss" scoped>
  @import '../../../../scss/mixins';

  .suspicious-group-join-reported {
    @include page-frame;
    font-size: 16px;
    line-height: 1.5;
    font-family: SimplerPro;

    .text {
      padding-top: 4px;
    }

    .text-bold {
      font-weight: bold;
      padding: 23px 0px 20px 0px;
    }

    .text-sub {
      padding-top: 1px;
    }

    .link {
      color: $riseup_blue;
      text-decoration: underline;
    }
  }
</style>
