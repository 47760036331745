<template>
  <div>
    <div class="card">
      <div class="card-top">
        <div class="card-title">
          הצטרף מס׳ לא מוכר
        </div>
        <div class="card-text">
          לחיצה על דיווח תעצור את הודעות התזרים עד שהצוות שלנו יחקור את העניין.
        </div>
      </div>
      <div class="buttons">
        <riseup-button :title="'דיווח על מס׳ לא מוכר'" :variant="'primary'" :action="reportGroupJoin"/>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '../../../../Segment';

export default {
  name: 'SuspiciousGroupJoinCard',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  computed: {
  },
  methods: {
    ...mapActions('suspiciousGroupJoin', ['reportSuspiciousGroupJoin']),
    async reportGroupJoin() {
      Segment.trackUserInteraction('ReportSuspiciousGroupJoinClicked', {});
      await this.reportSuspiciousGroupJoin();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../scss/mixins';

  .open-card {
    padding: 8px 0px 17px 0px;
  }

  .card {
    border-radius: 3px;
    box-shadow: 2px 2px 8px 0 $riseup_gray_1;
    margin-bottom: 16px;

    .card-top {
      padding: 21px 32px 0px 32px;

      .card-title {
        @include card-title;
        margin-bottom: 12px;
      }

      .card-text {
        font-size: 16px;
        line-height: 1.5;
        font-family: SimplerPro;
      }
    }

    .buttons {
      padding: 21px 30px 34px 30px;

      .riseup-button {
        width: 100%;
      }
    }
  }
</style>
